import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/common-config/report-heading/detail', orgId, reportTitle, data) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        pdfContent.push({ text: '', style: 'fertilizer' })

        const farmerDataRow = [
            [
                { text: data.$t('globalTrans.sl_no'), style: 'th' },
                { text: data.$t('bsri_report.Farmers_name'), style: 'th' },
                { text: data.$t('financialAid.farmer_father_name'), style: 'th' },
                { text: data.$t('dae_subsidy.nid'), style: 'th' },
                { text: data.$t('bsri_demandDIS.wallet_no'), style: 'th' },
                { text: data.$t('globalTrans.address'), style: 'th' },
                { text: data.$t('common_config.account_info'), style: 'th' },
                { text: data.$t('common_config.account_type'), style: 'th' },
                { text: data.$t('common_config.account_no'), style: 'th' },
                { text: data.$t('bsri_demandDIS.bank'), style: 'th' },
                { text: data.$t('bsri_demandDIS.branch'), style: 'th' },
                { text: data.$t('dae_subsidy.routing'), style: 'th' },
                { text: data.$t('bsri_demandDIS.allocated_subsidy_amount'), style: 'th' }
            ]
        ]
        data.farmers.forEach((farmerItem, index) => {
            farmerDataRow.push([
                { text: data.$n(index + 1), style: 'td' },
                { text: i18n.locale === 'bn' ? farmerItem.name_bn : farmerItem.name, style: 'td' },
                { text: i18n.locale === 'bn' ? farmerItem.father_name_bn : farmerItem.father_name, style: 'td' },
                { text: data.$n(farmerItem.nid_no, { useGrouping: false }), style: 'td' },
                { text: (i18n.locale === 'bn' ? '০' : '0') + data.$n(farmerItem.wallet_no, { useGrouping: false }), style: 'td' },
                { text: data.getAddress(farmerItem), style: 'td' },
                { text: farmerItem.account_type_name, style: 'td' },
                { text: farmerItem.ac_type_name, style: 'td' },
                { text: data.$n(farmerItem.account_no, { useGrouping: false }), style: 'td' },
                { text: farmerItem.bank_name, style: 'td' },
                // { text: farmerItem.farmer_data.ac_category_id === 1 ? farmerItem.ac_type_name : farmerItem.bank_name, style: 'td' },
                { text: farmerItem.branch_name, style: 'td' },
                { text: farmerItem.routing_no, style: 'td' },
                { text: data.$n(farmerItem.allocated_subsidy_amount, { useGrouping: false }), style: 'td' }
            ])
        })
        pdfContent.push({
            table: {
                dontBreakRows: true,
                headerRows: 1,
                widths: ['5%', '10%', '9%', '8%', '8%', '8%', '10%', '8%', '10%', '5%', '5%', '5%', '9%'],
                body: farmerDataRow
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'landscape',
            // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 0, 0, 4]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                hh: {
                    fontSize: 17,
                    bold: true,
                    margin: [25, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
