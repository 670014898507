
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_demandDIS.distribution_approval') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                text="text"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('bsri_demandDIS.mill_name')"
                            label-for="mill_info_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.mill_info_id"
                                text="text"
                                :options="millInfoList"
                                id="mill_info_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.farmer_distribution_approval') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template #head(action)="data">
                                        <span class="d-inline">Action</span><span size="sm" class="d-inline ml-2"><input type='checkbox' @change="check(data.item)" /></span>
                                        </template>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(unit_id)="data">
                                            {{ $n(data.item.unit_id, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_farmer)="data">
                                            <b-button v-b-modal.modal-5 variant=" iq-bg-success mb-1" @click="millwisefarmer(data.item)" size="sm" title="view" >{{ $n(data.item.total_farmer, { useGrouping: false }) }}</b-button>
                                        </template>
                                        <template v-slot:cell(total_subsidy_amount)="data">
                                          {{ $n(data.item.total_subsidy_amount, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status === 1">{{$t('bsri_demandDIS.not_disburse')}}</span>
                                            <span class="badge badge-danger" v-else-if ="data.item.status === 2">{{$t('bsri_demandDIS.approve')}}</span>
                                            <span class="badge badge-danger" v-else-if ="data.item.status === 3">{{$t('bsri_demandDIS.reject')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-show variant=" iq-bg-success mr-1 mb-1" size="sm" @click="show(data.item)"><i class="ri-eye-line m-0"></i></b-button> -->
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" v-if="data.item.verfied != 1" @click="detail(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_status" v-b-modal.modal-4 title="Payment" @click="detail(data.item)"><i class="fas fa-money-check-alt"></i></a>
                                            <a href="javascript:" class="btn_table_action"><input type='checkbox' class="actioncheckbox"/></a>
                                            <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-success mb-1" @click="millwisefarmer(data.item)" size="sm" title="view"><i class="fas fa-eye m-0"></i></b-button> -->
                                        </template>
                                    </b-table>
                                </b-col>
                                </b-row>
                                <b-row>
                                    <b-button @click="approvalButton()" type="submit" variant="success" class="mr-5 p-3 float-right ml-auto">{{ $t('bsri_demandDIS.payment') }}</b-button>
                                </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Detail :item="item" :key="item.id"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('bsri_demandDIS.subsidy_dist_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
            <template>
                <b-container fluid>
                    <b-overlay :show="loadingState">
                        <b-row style="height: 100%;overflow: scroll;">
                            <b-col>
                                <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                    <b-thead>
                                        <b-tr>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Farmers_name') }}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('financialAid.farmer_father_name') }}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('dae_subsidy.nid') }}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_demandDIS.wallet_no') }}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('globalTrans.address') }}</b-th>
                                            <!-- <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_demandDIS.approved_land_quantity') }}</b-th> -->
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('common_config.account_info')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{$t('common_config.account_type')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{$t('common_config.account_no')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{$t('bsri_demandDIS.bank')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{$t('bsri_demandDIS.branch')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{$t('dae_subsidy.routing')}}</b-th>
                                            <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_demandDIS.allocated_subsidy_amount') }}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(info, index3) in farmers" :key="index3">
                                            <b-th style="text-align: center;vertical-align: middle;">{{ $n(index3 + 1) }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ currentLocale === 'en' ? info.name : info.name_bn }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ currentLocale === 'en' ? info.father_name : info.father_name_bn }}</b-th>
                                            <b-td style="text-align: center;vertical-align: middle;">{{ $n(info.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.wallet_no | mobileNumber }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ getAddress(info) }}</b-th>
                                            <!-- <b-th style="text-align: center;vertical-align: middle;">{{ $n(info.approved_land_quantity) }}</b-th> -->
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.account_type_name }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.ac_type_name }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ $n(info.account_no, { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.bank_name }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.branch_name }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ info.routing_no }}</b-th>
                                            <b-th style="text-align: center;vertical-align: middle;">{{ $n(info.allocated_subsidy_amount) }}</b-th>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>
<script>
import Detail from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionRegistrarfarmers, distributionRegistrarList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export-pdf_details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Detail
  },
  data () {
    return {
        fields: [],
        search: {
            fiscal_year_id: 0,
            mill_info_id: 0,
            defloading: false,
            subzone_office_id: 0,
            center_id: 0,
            unit_id: 0
        },
        farmers: [],
        fiscalYearId: '',
        rows: [],
        subzoneOfficeList: [],
        centerList: [],
        unitList: [],
        item: '',
        accountTypeList: [
            { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
            { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
        ]
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    millInfoList: function () {
      const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      return millInfoList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.farmer_info') : this.$t('bsri_demandDIS.farmer_info') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
                {
                  key: 'index',
                  label: this.$t('globalTrans.sl_no'),
                  class: 'text-center'
                },
                {
                    key: 'fiscal_year_bn',
                    label: this.$t('bsri_demandDIS.fiscal_year')
                },
                {
                    key: 'mill_name_bn',
                    label: this.$t('bsri_demandDIS.mill_name')
                },
                {
                    key: 'total_farmer',
                    label: this.$t('bsri_demandDIS.total_farmers'),
                    class: 'text-right'
                },
                {
                    key: 'total_subsidy_amount',
                    label: this.$t('bsri_demandDIS.total_subsidy_amount'),
                    class: 'text-right'
                },
                // {
                //     key: 'status',
                //     label: this.$t('globalTrans.status')
                // },
                {
                    key: 'action',
                    label: this.$t('globalTrans.action')
                }
            ]
      } else {
        return [
                {
                  key: 'index',
                  label: this.$t('globalTrans.sl_no'),
                  class: 'text-center'
                },
                {
                    key: 'fiscal_year',
                    label: this.$t('bsri_demandDIS.fiscal_year')
                },
                {
                    key: 'mill_name',
                    label: this.$t('bsri_demandDIS.mill_name')
                },
                {
                    key: 'total_farmer',
                    label: this.$t('bsri_demandDIS.total_farmers'),
                    class: 'text-right'
                },
                {
                    key: 'total_subsidy_amount',
                    label: this.$t('bsri_demandDIS.total_subsidy_amount'),
                    class: 'text-right'
                },
                // {
                //     key: 'status',
                //     label: this.$t('globalTrans.status')
                // },
                {
                    key: 'action',
                    label: this.$t('globalTrans.action')
                }
            ]
      }
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.mill_info_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.subzoneOfficeList = this.getSubZoneList(newVal)
        this.centerList = []
        this.unitList = []
      }
    },
    'search.subzone_office_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.centerList = this.getCenterList(newVal)
      }
    },
    'search.center_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
         this.unitList = this.getUnitList(newVal)
      }
    }
  },
  created () {
   this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    check () {
          var chks = document.getElementsByClassName('actioncheckbox')
          var l = 0
          for (l = 0; l < chks.length; l++) {
             chks[l].checked = !chks[l].checked
          }
    },
    async millwisefarmer (data) {
        this.defloading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(incentiveGrantServiceBaseUrl, distributionRegistrarfarmers, data).then(response => {
        if (response.success) {
          this.farmers = this.getFarmerRelationalData(response.data)
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        } else {
          this.defloading = false
        }
      })
    },
    getFarmerRelationalData (infos) {
        return infos.map(data => {
            const accountTypeData = this.accountTypeList.find(type => type.value === data.ac_category_id)
            const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.$i18n.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
            const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === data.ac_type_id)
            const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.$i18n.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
            const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === data.bank_id)
            const bankData = { bank_name: bankObj !== undefined ? (this.$i18n.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
            const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === data.branch_id)
            const branchData = { branch_name: branchObj !== undefined ? (this.$i18n.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
            return Object.assign({}, data, accountGroupData, bankData, branchData, acTypeData)
        })
    },
    show (item) {
      this.editItemId = item.id
      this.item = item
    },
    detail (item) {
      this.item = item
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, distributionRegistrarList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data))
        //  this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getSubZoneList: function (millInfoId) {
        const subZoneList = this.$store.state.incentiveGrant.commonObj.subZoneList.filter(item => item && item.mill_info_id === millInfoId)
        return subZoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getCenterList: function (subZoneId) {
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item && item.subzone_office_id === subZoneId)
        return centerList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    getUnitList: function (centerId) {
      const unitList = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === centerId)
      return unitList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.unit_no, text: this.$n(item.text_bn) }
          } else {
              return { value: item.unit_no, text: item.text_en }
          }
      })
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
                const fiscalYearData = {}
                if (typeof fiscalYearObj !== 'undefined') {
                    fiscalYearData.fiscal_year = fiscalYearObj.text_en
                    fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
                } else {
                    fiscalYearData.fiscal_year = ''
                    fiscalYearData.fiscal_year_bn = ''
                }
            const millTypeObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millTypeList => millTypeList.value === parseInt(item.mill_info_id))
                const millTypeObjData = {}
                    if (typeof millTypeObj !== 'undefined') {
                        millTypeObjData.mill_name = millTypeObj.text_en
                        millTypeObjData.mill_name_bn = millTypeObj.text_bn
                    } else {
                        millTypeObjData.mill_name = ''
                        millTypeObjData.mill_name_bn = ''
                    }

            const SubzoneOfficeObj = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.find(sobzoneOfficeList => sobzoneOfficeList.value === parseInt(item.subzone_office_id))
                const SubzoneOfficeData = {}
                    if (typeof SubzoneOfficeObj !== 'undefined') {
                        SubzoneOfficeData.subzone_office_name = SubzoneOfficeObj.text_en
                        SubzoneOfficeData.subzone_office_name_bn = SubzoneOfficeObj.text_bn
                    } else {
                        SubzoneOfficeData.subzone_office_name = ''
                        SubzoneOfficeData.subzone_office_name_bn = ''
                    }

            const centerListObj = this.$store.state.incentiveGrant.commonObj.centerList.find(centerList => centerList.value === parseInt(item.center_id))
                const centerListData = {}
                    if (typeof centerListObj !== 'undefined') {
                        centerListData.center = centerListObj.text_en
                        centerListData.center_bn = centerListObj.text_bn
                    } else {
                        centerListData.center = ''
                        centerListData.center_bn = ''
                    }
            const unitListObj = this.$store.state.incentiveGrant.commonObj.unitList.find(unitData => unitData.value === parseInt(item.unit_id))
                const unitListData = {}
                    if (typeof centerListObj !== 'undefined') {
                        unitListData.unit = unitListObj.text_en
                        unitListData.unit_bn = unitListObj.text_bn
                    } else {
                        unitListData.unit = ''
                        unitListData.unit_bn = ''
                    }
          return Object.assign({}, item, fiscalYearData, millTypeObjData, SubzoneOfficeData, centerListData, unitListData)
        })
        return listData
    },
    getAddress (item) {
        const address = []
        const unionList = this.$store.state.commonObj.unionList
        const tmpUnion = unionList.find(union => item.union_id !== 0 && union.value === item.union_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpUnion !== undefined ? tmpUnion.text_bn : '')
        } else {
            address.push(tmpUnion !== undefined ? tmpUnion.text_en : '')
        }
        const upazilaList = this.$store.state.commonObj.upazilaList
        const tmpUpazila = upazilaList.find(upazila => item.upazilla_id !== 0 && upazila.value === item.upazilla_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpUpazila !== undefined ? tmpUpazila.text_bn : '')
        } else {
            address.push(tmpUpazila !== undefined ? tmpUpazila.text_en : '')
        }
        const districtList = this.$store.state.commonObj.districtList
        const tmpDistrict = districtList.find(district => item.district_id !== 0 && district.value === item.district_id)
        if (this.$i18n.locale === 'bn') {
            address.push(tmpDistrict !== undefined ? tmpDistrict.text_bn : '')
        } else {
            address.push(tmpDistrict !== undefined ? tmpDistrict.text_en : '')
        }
        return address.join(', ')
    },
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Subsidy Distribution (Farmer)' : 'ভর্তুকি বিতরণ (কৃষক)'
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, reportTitle, this)
    }
  }
}
</script>
